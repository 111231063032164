<template>
    <!-- <a-date-picker
        v-model="valueDate"
        style="width: 100%;"
        :placeholder="placeholder"
        :format="dateFormat"
        @focus="focus"
        @change="changeInput" /> -->
    <div :ref="`calendar_${widgetName}`">
        <Datepicker 
            v-model="valueDate"
            :focusHandler="focus"
            :dateFormat="dateFormat"
            :getCalendarContainer="getCalendarContainer"
            :placeholder="placeholder"
            @input="changeInput" />
    </div>
</template>

<script>
import {formatsInMoments} from '@/utils/dateSettings'
import Datepicker from '@apps/Datepicker'
export default {
    name: 'DateTimeField',
    components: {
        Datepicker
    },
    props: {
        value: {
            type: [String, Number, Date, null],
        },
        dateFormat: {
            type: String,
            default: "DD-MM-YYYY",
        },
        placeholder: {
            type: String,
            default: "__/__/____",
        },
        wConfig: {
            type: Object,
            default: () => null,
        },
        currentDate: {
            type: Boolean,
            default: false,
        },

        time: {
            type: Boolean,
            default: false
        },
        opened: {
            type: Boolean,
            default: false
        },
        enterHandler: {
            type: Function,
            default: () => {}
        },
        focus: {
            type: Function,
            default: () => {}
        },
        widgetName: {
            type: String,
            required: true
        }
    },
    computed: {
        getCalendarContainer() {
            return () => this.$refs[`calendar_${this.widgetName}`]
        }
    },
    data() {
        return {
            visible: false,
            valueDate: "",
            valueInput: "",
            edit: true,
            masks: {
                input: this.dateFormat,
                inputTime: this.dateFormat,
                inputTime24hr: this.dateFormat,
                inputDateTime24hr: this.dateFormat,
                inputDateTime: this.dateFormat
            },
            mask: this.dateFormat.replace(/[A-Z,a-z]/g, '#')
        }
    },
    created() {
        if(this.currentDate && !this.value)
            this.valueDate = new Date()

        if(this.value)
            this.valueDate = this.$moment(this.value, [this.dateFormat, ...formatsInMoments ])
    
        if(this.value === null){
            this.valueInput = ""
        }

        if(this.opened)
            this.visible = true
    },
    methods: {
        inputFocus() {
            this.$refs.input_datetime.focus()
            this.$refs.input_datetime.select()
        },
        popupVisibleChange(val) {
            if(!val){
                this.$emit('hidePopover')
            } else {
                const datepicker = this.$refs.datepicker
                const date = this.$moment(this.valueInput, this.dateFormat).toDate()

                if(isNaN(date)) { 
                    // this.valueDate = new Date()
                } else
                    this.valueDate = date

                if(datepicker)
                    datepicker.move(this.valueDate);
            }
        },
        changeInput(newDate) {
            const date = newDate ? this.$moment(newDate, this.dateFormat).format(this.dateFormat) : null

            this.$emit('input', date)
            this.$emit('change')
        },
        visibleChange(){
            if(!this.visible)
                this.$emit('hidePopover')
        },
        // focus(){
        //     this.$emit('focus')
        // }
    }
}
</script>
